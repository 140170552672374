<template>
  <div class="q-pa-md">
    <q-card>
      <q-card-section class="row border-bottom items-center full-width q-py-xs q-pl-none">
        <q-legend
            :label="$t('Items')"
            text-class="text-h6"
        />

        <search
            dense
            autoset
            is-expandable
            @submit="handleSearch"
        />

        <q-space/>

        <q-btn
            :color="serverParams.filter && serverParams.filter.length > 0 ? 'light-blue-9' : 'dark'"
            text-color="white"
            size="sm"
            class="q-mr-sm"
            :label="filterBtnText"
            no-caps
            unelevated
            @click="openCloseFilters"
        />

        <q-btn
            color="dark"
            text-color="white"
            :label="$t('Refresh')"
            size="sm"
            class="q-mr-sm"
            no-caps
            unelevated
            @click="refreshItems"
        />
      </q-card-section>

      <q-card-section class="q-ma-none q-pa-none">
        <filters-collapse
            :is-open="isOpenFilters"
            :options="{
              defaultFilter: serverParams.filter,
              fields: activatedFields,
              values: {
                'states==i': states,
              }
            }"
            @submit="handleFiltersSubmit"
            @close="openCloseFilters"
            @update="handleFilterUpdate"
        />
      </q-card-section>

      <q-card-section
          v-if="isNeededWarehouse"
          class="row bg-teal border-bottom q-pa-sm items-center justify-center text-center text-subtitle1"
          style="min-height: 80vh;"
      >
        {{ $t('You need to create warehouse. You can make this from') }}

        <router-link
            to="/warehouse"
            class="text-success q-ml-sm"
        >
          {{ $t('here.') }}
        </router-link>
      </q-card-section>

      <q-card-section
          v-else
          class="q-pa-none"
      >
        <q-table
            style="height: calc(100vh - 130px);"
            class="sticky-header-table"
            row-key="id"
            :rows-per-page-label="$t('Rows per page')"
            :rows="storageItems"
            :columns="columns"
            v-model:pagination="pagination"
            :loading="storageItemsLoading"
            :filter="filter"
            :rows-per-page-options="[25, 50, 100, 150, 200, 250]"
            virtual-scroll
            binary-state-sort
            flat
            @request="onRequest"
        >

          <template v-slot:loading>
            <q-inner-loading
                showing
                color="primary"
            />
          </template>

          <template v-slot:body="props">
            <items-row
                :data="props"
            />
          </template>

        </q-table>
      </q-card-section>
    </q-card>
  </div>
</template>

<script>
// Vuex
import { mapActions, mapGetters, mapMutations } from 'vuex'

// Mixins
import TableMixin from '../../components/global/TableMixin'

// Components
import FiltersCollapse from '../../components/filters/FilterCollapse'
import Search from '../../components/search/Search'
import ItemsRow from '../../components/items/ItemsRow'

// Utils
import { buildQuery } from '../../utils/query-utils'

export default {
  name: 'Items',
  components: {
    FiltersCollapse,
    Search,
    ItemsRow
  },
  mixins: [
    TableMixin
  ],
  data () {
    return {
      filter: '',
      isOpenFilters: false,
      isNeededWarehouse: false,
      pagination: {
        descending: true,
        page: 1,
        rowsPerPage: 25,
        rowsNumber: 25
      },
      states: [
        { id: 'normal', title: this.$t('Normal') },
        { id: 'delivered', title: this.$t('Delivered') },
        { id: 'blocked', title: this.$t('Blocked') },
        { id: 'booked', title: this.$t('Booked') },
        { id: 'deleted', title: this.$t('Deleted') },
        { id: 'new', title: this.$t('New') },
        { id: 'returned', title: this.$t('Returned') },
        { id: 'shipped', title: this.$t('Shipped') },
        { id: 'expected', title: this.$t('Expected') },
        { id: 'defected', title: this.$t('Defected') }
      ],
      columns: [
        {
          label: this.$t('Id'),
          name: 'id',
          align: 'center'
        },
        {
          label: this.$t('Status'),
          name: 'state',
          align: 'left'
        },
        {
          label: this.$t('Type'),
          name: 'type',
          align: 'left'
        },
        {
          label: this.$t('Product offer'),
          name: 'productOffer',
          align: 'left'
        },
        {
          label: this.$t('Warehouse'),
          name: 'warehouse',
          align: 'center'
        },
        {
          label: this.$t('Reserve'),
          name: 'reserve',
          align: 'left'
        },
        {
          label: this.$t('Place'),
          name: 'place',
          align: 'center'
        },
        {
          label: this.$t('Sku'),
          name: 'sku',
          align: 'center'
        },
        {
          label: this.$t('Purchase order'),
          name: 'createdByDocument',
          align: 'center'
        },
        {
          label: this.$t('Inbound item'),
          name: 'acceptanceItem',
          align: 'center'
        },
        {
          label: this.$t('Price'),
          name: 'price',
          align: 'center'
        },
        {
          label: this.$t('Dimensions'),
          name: 'dimensions',
          align: 'center'
        },
        {
          label: this.$t('Document'),
          name: 'document',
          align: 'center'
        },
        {
          label: this.$t('Batch'),
          name: 'batch',
          align: 'center'
        }
      ],
      activatedFields: [
        'id',
        'sku',
        'warehouse',
        'weight.to',
        'weight.from',
        'state==i',
        'createdByDocumentId',
        'acceptanceItem',
        'productOffer==i'
      ],
    }
  },
  mounted () {
    this.loadDefaultItems()
  },
  computed: {
    ...mapGetters([
      'totalStorageItemsNumber',
      'storageItemsLoading',
      'storageItems'
    ]),
    filterBtnText () {
      return this.serverParams.filter && this.serverParams.filter.length > 0
          ? this.$t('Filtered: ') + this.totalStorageItemsNumber
          : this.$t('Filter')
    },
  },
  methods: {
    ...mapActions([
      'loadStorageItems'
    ]),
    ...mapMutations([]),
    handleSearch (search) {
      return this.onRequest({ pagination: { page: 1, search } })
    },
    onRequest (data = {}) {
      this.pagination = data.pagination || {}
      const query = buildQuery(this.pagination)

      if (query.search && query.search[query.search.length - 1] !== '*' && query.search[query.search.length - 2] !== ':' && !query.search.includes('%')) {
        query.search += query.search[query.search.length - 1] === ':'
            ? '*'
            : ':*'
      }

      this.updateParams(query)

      return this.loadStorageItems(this.serverParams)
          .then(({ page, totalItems }) => {
            this.pagination = {
              ...this.pagination,
              page,
              rowsNumber: totalItems
            }

          })
          .catch(err => {
            if (err.message === 'No warehouses found') {
              this.isNeededWarehouse = true
            }
          })
    },
    openCloseFilters () {
      this.isOpenFilters = !this.isOpenFilters
    },
    refreshItems () {
      return this.onRequest({
        pagination: {
          forceReload: true
        }
      })
    },
    handleFiltersSubmit (filter) {
      this.isOpenFilters = false
      return this.onRequest({ pagination: { filter, page: 1 } })
    },
    handleFilterUpdate ({ value, oldValue }) {
      if (oldValue['state==i'].length <= 0 && value['state==i'].length > 0 && !value.items.from) {
        value.items.from = 1
      }
    },
    loadDefaultItems () {
      return this.onRequest({ pagination: {} })
    },
  }
}
</script>